<script lang="ts">
  /**
   * Imports
   * ---------------------------------------------------------------------------
   */
  import Icon from "@elements/Icon.svelte";
  import { Pagination, type LinkType } from "flowbite-svelte";
  import { twMerge } from "tailwind-merge";

  /**
   * Props
   * ---------------------------------------------------------------------------
   */
  export let pages: LinkType[] = [];
  export let previousHref: string | undefined = undefined;
  export let nextHref: string | undefined = undefined;
  export let ulClass: string | undefined = undefined;

  /**
   * Misc
   * ---------------------------------------------------------------------------
   */
  const sharedPrevNext =
    "text-white stroke-white hover:stroke-p100 hover:text-p100 uppercase " +
    "border-solid border-white inline-flex items-center";
</script>

<Pagination
  {pages}
  normalClass="border-none text-white bg-none hover:text-p100"
  activeClass="border-none text-p100"
  ulClass={twMerge(
    "bg-none inline-flex -space-x-px rtl:space-x-reverse items-center list-none p-0 my-2",
    ulClass,
  )}
  on:previous={() => {
    if (previousHref) {
      window.location.href = previousHref;
    }
  }}
  on:next={() => {
    if (nextHref) {
      window.location.href = nextHref;
    }
  }}
>
  <svelte:fragment slot="prev">
    <span class={!previousHref ? "hidden" : `pr-4 border-r ${sharedPrevNext}`}>
      <Icon class="mr-1 fill-transparent" name="chevron-left" size={20} /> Previous
    </span>
  </svelte:fragment>
  <svelte:fragment slot="next">
    <span class={!nextHref ? "hidden" : `pl-4 border-l ${sharedPrevNext}`}>
      Next <Icon class="ml-1 fill-transparent" name="chevron-right" size={20} />
    </span>
  </svelte:fragment>
</Pagination>
